<template>
  <div>

    <div class="vs-list--header title-list vs-header-list-primary">
      <div class="list-titles">
        <div class="vs-list--title">{{academicReference.name_institution}}</div>            
      </div>
    </div>            

    <div class="vs-list list-academic-details">

      <div class="vs-list--item item-list step-checklist" v-for="step in steps" :key="step.key">
        <template v-if="academicReference.checklist[step.key].status > 0">
          <div class="vs-list--icon">
            <vs-icon class="icon-step" icon="alarm" color="warning" v-if="academicReference.checklist[step.key].status == 1"></vs-icon>
            <vs-icon class="icon-step" icon="done" color="success" v-if="academicReference.checklist[step.key].status == 2"></vs-icon>
            <vs-icon class="icon-step" icon="clear" color="danger" v-if="academicReference.checklist[step.key].status == 3"></vs-icon>
          </div>
          <div class="list-titles">
            <div class="vs-list--title title-step">{{step.text}}</div>
            <div class="vs-list--subtitle">{{step.textInformation[academicReference.checklist[step.key].status]}}</div>
          </div>
        </template>
      </div>

    </div>  
  </div>

</template>

<script>
import steps from '../../mixins/steps'
export default {
  mixins:[steps],
  props: {
    project: Object,
    academicReferenceId: Object
  },
  data (){
    return{
      academicReference: null,
    }
  },
  methods: {

  },
  watch: {

  },
  mounted () {
    this.academicReference = this.project.academic_references.filter( (academic_reference) => academic_reference.id == this.academicReferenceId)[0]
  },
}
</script>

<style scoped>
  .list-academic-details{
    display: flex !important;
    margin-bottom: 0.5rem;
  }
  .title-step{
    color: black !important;
  }
  .step-checklist{
    border-bottom: none !important;
  }
  .vs-list--icon i{
    font-size: 2.0rem !important;
  }
  
</style>