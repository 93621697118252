var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "text-center" }, [
            _c("h4", [
              _vm._v("Observaciones realizadas por el equipo de operaciones"),
            ]),
          ]),
          _c("br"),
          _c(
            "vs-row",
            { attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "9",
                    "vs-sm": "9",
                    "vs-xs": "9",
                  },
                },
                [
                  _vm._v(
                    "\n        Haz clic en cada opción para conocer lo que nuestro equipo encontró en las verificaciones\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("br"),
          _vm.progressReport
            ? _c("vs-progress", {
                attrs: { indeterminate: "", color: "primary" },
              })
            : _vm._e(),
          _vm.project.observations != null
            ? [
                Object.keys(_vm.project.observations).length > 0
                  ? _c(
                      "vs-tabs",
                      _vm._l(
                        _vm.project.observations,
                        function (observations, index_observation) {
                          return _c(
                            "div",
                            { key: index_observation },
                            [
                              _vm.showObservations(observations[0].type)
                                ? _c(
                                    "vs-tab",
                                    {
                                      attrs: {
                                        label: "Equipo " + observations[0].type,
                                      },
                                    },
                                    [
                                      _c(
                                        "vs-table",
                                        { attrs: { noDataText: "" } },
                                        [
                                          _c("template", { slot: "header" }),
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c("vs-th", [
                                                _vm._v(
                                                  "\n                  Fecha\n                "
                                                ),
                                              ]),
                                              _c("vs-th", [
                                                _vm._v(
                                                  "\n                  Observación\n                "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            observations,
                                            function (
                                              observation,
                                              index_observation
                                            ) {
                                              return _c(
                                                "vs-tr",
                                                { key: index_observation },
                                                [
                                                  _c(
                                                    "vs-td",
                                                    {
                                                      attrs: {
                                                        data: observation.created_at,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " \n                    " +
                                                          _vm._s(
                                                            _vm.humanizeDate(
                                                              observation.created_at
                                                            )
                                                          ) +
                                                          "                 \n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "vs-td",
                                                    {
                                                      attrs: {
                                                        data: observation.created_at,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            observation.observation
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.project.project_type === 0 ||
                              _vm.project.project_type === 6 ||
                              _vm.project.project_type === 11
                                ? _c(
                                    "vs-tab",
                                    { attrs: { label: "Visita domiciliaria" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-row" },
                                        _vm._l(
                                          _vm.project.visit_concept,
                                          function (
                                            visit_concept_item,
                                            index_visit_concept
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: index_visit_concept,
                                                staticClass:
                                                  "vx-col w-full lg:w-1/3 mb-base",
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      Object.keys(
                                                        visit_concept_item
                                                      )[0]
                                                    ) + ": "
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      visit_concept_item[
                                                        Object.keys(
                                                          visit_concept_item
                                                        )
                                                      ]
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("modal-message", { attrs: { modalActive: _vm.progressReport } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }