<template>
  <div>
    <vx-card>
      <template v-if="project.forms != null">
        <vs-tabs v-if="Object.keys(project.forms).length > 0">
          <vs-tab v-bind:label="form.title" v-for="(form, index_form) in project.forms" :key="index_form">
            <div class="con-tab-ejemplo">
              <vs-table stripe noDataText="">
                <template>
                  <vs-tr v-for="(row, index_row) in chunk(form.fields, 3)" :key="index_row" >
                    <vs-td width="33%" v-for="(col, index_col) in row" :key="index_col">
                      <h6 class="table-title">{{Object.keys(col)[0]}}:</h6>
                      <br>
                      {{col[Object.keys(col)[0]]}}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <GmapMap :center="getCoordinates(form)" :zoom="15" map-type-id="roadmap" style="width: 100%; height: 400px" v-if="form.title == 'Vivienda'">
                <GmapMarker :position="getCoordinates(form)"/>
              </GmapMap>
            </div>
          </vs-tab>
        </vs-tabs>
      </template>
    </vx-card>
    <br>
    <vx-card>
      <template v-if="project.relations">
        <vs-tabs v-if="Object.keys(project.relations).length > 0">
          <vs-tab v-bind:label="Object.keys(form)[0]" v-for="(form, index_form) in project.relations" :key="index_form">
            <div class="con-tab-ejemplo">
              <div class="ref" v-for="(data_refs, index_refs) in Object.values(form[Object.keys(form)[0]])[0]" :key="index_refs">
                <academic-details-show :project="project" :academicReferenceId="data_refs.fields[0]['Identificador']" v-if=" Object.keys(form) =='Referencias académicas'"/>
                <vs-table stripe noDataText="">
                  <template>
                    <vs-tr v-for="(ref, index_ref) in chunk(data_refs.fields, 3)" :key="index_ref" >
                      <vs-td width="33%" v-for="(data, index_col) in ref" :key="index_col">
                        <h6 class="table-title">{{Object.keys(data)[0]}}:</h6>
                        <template v-if="Object.keys(data)[0] == 'Observaciones'">
                          <textarea class="textarea-read-only" v-model="data[Object.keys(data)[0]]" cols="60" rows="10" disabled></textarea>
                        </template>
                        <template v-else>
                          <br>
                          {{data[Object.keys(data)[0]]}}
                        </template>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
                <vs-divider/>
              </div>
              <template v-if="observationTeam(Object.keys(form)) != null">
                <vs-tabs v-if="Object.keys(observationTeam(Object.keys(form))).length > 0">
                  <vs-tab v-bind:label="`Observaciones Equipo ${observations[0].type}`" v-for="(observations, index_observation) in observationTeam(Object.keys(form))" :key="index_observation">
                    <vs-table noDataText="">
                      <template slot="header">
                      </template>
                      <template slot="thead">
                        <vs-th>
                          Fecha
                        </vs-th>
                        <vs-th>
                          Observación
                        </vs-th>
                      </template>

                      <template>
                        <vs-tr v-for="(observation, index_observation) in observations" :key="index_observation" >
                          <vs-td :data="observation.created_at">
                            {{ humanizeDate(observation.created_at) }}
                          </vs-td>

                          <vs-td :data="observation.created_at">
                            {{observation.observation}}
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </vs-tab>
                  <vs-tab label="Visita domiciliaria" v-if="project.project_type === 0 || project.project_type === 6 || project.project_type === 11">
                    <div class="vx-row">
                      <div v-for="(visit_concept_item, index_visit_concept) in project.visit_concept" :key="index_visit_concept" class="vx-col w-full lg:w-1/3 mb-base">
                        <b>{{Object.keys(visit_concept_item)[0]}}: </b> {{visit_concept_item[Object.keys(visit_concept_item)]}}
                      </div>
                    </div>
                  </vs-tab>
                </vs-tabs>
              </template>
            </div>
          </vs-tab>
        </vs-tabs>
      </template>
    </vx-card>
    <br>
    <vx-card>
      <template v-if="project.pila_info !== null">
        <vs-tabs>
          <vs-tab label="Histórico laboral">
              <div v-if="project.pila_info.has_pila_periods" class="con-tab-ejemplo observation-card">
                  <div class="observation-text">
                    <span>
                      Tras revisar en la base de datos PILA de enlace operativo encontramos información adicional confiable sobre el histórico laboral del candidato
                      <strong> durante los últimos 36 meses, correspondiente al período de recopilación de datos de PILA.</strong>
                    </span>
                  </div>

                  <div class="observation-text">
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="5">
                          <strong>Empresa:</strong>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="5">
                          <strong>Periodo:</strong>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="2">
                          <strong>Duración:</strong>
                        </vs-col>
                    </vs-row>
                    <br>
                    <vs-row style="margin-bottom: 2%" v-for="(pila_period, i) in project.pila_info.pila_periods" :key="i">
                        <vs-col vs-type="flex" vs-justify="start" vs-align="top" vs-w="4" v-if="i < 5">
                          {{ pila_period.company }}
                        </vs-col>
                        <vs-col vs-type="" vs-justify="" vs-align="" vs-w="8" v-if="i < 5">
                          <vs-row v-for="(period, j) in pila_period.periods_per_company" :key="j">
                              <vs-col vs-type="flex" vs-justify="" vs-align="" vs-w="9">
                                  {{ period.start_month }} {{ period.start_year }} a {{ period.end_month ? period.end_month : 'No reporta' }} {{ period.end_year }}
                              </vs-col>
                              <vs-col vs-type="flex" vs-justify="" vs-align="" vs-w="3">
                                  {{ period.months }} {{ period.months == '1' ? 'Mes' : 'Meses '}}
                              </vs-col>
                          </vs-row>
                        </vs-col>
                    </vs-row>
                </div>
                </div>
                <div v-else>
                  <div class="observation-text">
                    <span>
                      <strong>No se ha encontrado información adicional sobre el histórico laboral </strong>
                      del candidato en la base de datos PILA de enlace operativo.
                    </span>
                  </div>
                </div>
          </vs-tab>
        </vs-tabs>
      </template>
    </vx-card>

  </div>
</template>

<script>
import api from '../../mixins/api.js'
import AcademicDetailsShow from './academic-details-show.vue'
export default {
  mixins: [api],
  props: {
    project: Object
  },
  data (){
    return{

    }
  },
  methods: {
    observationTeam(team){
      let object = {}
      let type = ''
      let index = null
      if (team != null){
        if (team.length > 0){
          switch (team[0]) {
            case 'Referencias laborales':
              type = 'Laboral'
              break;
            case 'Referencias académicas':
              type = 'Académico'
              break;
            case 'Referencias personales':
              type = 'Personal'
              break;
            default:
              break;
          }
          if (type != ''){
            for (let i = 0; i < this.project.observations.length; i++) {
              if (this.project.observations[i][0].type == type){
                index = i
              }
            }
          }
          if (index != null){
            object = [this.project.observations[index]]
          }
        }
      }
      return object
    },
    humanizeDate(date){
      var arrayDate = date.split("T")
      var stringDate = arrayDate[0]
      var hour = arrayDate[1].split(".")[0]
      return stringDate + ", " + hour
    },
    chunk(array, size) {
      const chunked_arr = [];
      if(array != null){
        for (let i = 0; i < array.length; i++) {
          const last = chunked_arr[chunked_arr.length - 1];
          if (!last || last.length === size) {
            chunked_arr.push([array[i]]);
          } else {
            last.push(array[i]);
          }
        }
      }
      return chunked_arr;
    },
    getCoordinates(form){
      var data = form.fields;
      var latitude = 6.2405563
      var longitude = -75.5862437
      data.forEach(object => {
        if (Object.keys(object)[0] == "project_latitude"){
          latitude = parseFloat(object[Object.keys(object)[0]])
        }
        if (Object.keys(object)[0] == "project_longitude"){
          longitude = parseFloat(object[Object.keys(object)[0]])
        }
      });
      var coordinates = { lat: latitude, lng: longitude }
      return coordinates
    },
    chunk(array, size) {
      const chunked_arr = [];
      if(array != null){
        for (let i = 0; i < array.length; i++) {
          const last = chunked_arr[chunked_arr.length - 1];
          if (!last || last.length === size) {
            chunked_arr.push([array[i]]);
          } else {
            last.push(array[i]);
          }
        }
      }
      return chunked_arr;
    }
  },
  created(){

  },
  mounted(){

  },
  components:{
    'academic-details-show': AcademicDetailsShow
  }
}
</script>

<style scoped>
  .textarea-read-only{
    border: none;
    background: white;
    margin-top: 1rem;
    resize: none;
    font-family: 'Montserrat';
    font-weight: 400;
    color: #626262;
  }
</style>
