var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c(
            "vs-row",
            { attrs: { "vs-w": "12" } },
            [
              _c("vs-col", {
                attrs: {
                  "vs-type": "flex",
                  "vs-justify": "center",
                  "vs-align": "center",
                  "vs-lg": "2",
                  "vs-sm": "4",
                  "vs-xs": "12",
                },
              }),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "8",
                    "vs-sm": "4",
                    "vs-xs": "12",
                  },
                },
                [
                  _c("div", { staticClass: "text-center" }, [
                    _c("h4", {}, [
                      _vm._v(_vm._s(_vm.project.analyst_information.title)),
                    ]),
                  ]),
                ]
              ),
              _c("vs-col", {
                attrs: {
                  "vs-type": "flex",
                  "vs-justify": "center",
                  "vs-align": "center",
                  "vs-lg": "2",
                  "vs-sm": "4",
                  "vs-xs": "12",
                },
              }),
            ],
            1
          ),
          _c(
            "vs-row",
            { attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "4",
                    "vs-sm": "4",
                    "vs-xs": "12",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-card__collapsible-content vs-con-loading__container",
                    },
                    [
                      _c("div", { staticClass: "vx-card__body" }, [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "con-vs-avatar m-0 45px",
                                staticStyle: {
                                  width: "45px",
                                  height: "45px",
                                  background: "rgb(195, 195, 195)",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "con-img vs-avatar--con-img" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://pixinvent.com/demo/vuesax-vuejs-admin-dashboard-template/demo-1/img/user-01.d09da647.jpg",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "ml-4" }, [
                            _c("h6", [
                              _vm._v(
                                _vm._s(_vm.project.analyst_information.name)
                              ),
                            ]),
                            _c("small", [
                              _vm._v(
                                _vm._s(
                                  _vm.humanizeDate(
                                    _vm.project.analyst_information.created_at
                                  )
                                ) +
                                  ", " +
                                  _vm._s(
                                    _vm.project.analyst_information.city_name
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "mt-5" }, [
                          _c("h6", [_vm._v("Email:")]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.project.analyst_information.email)
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "8",
                    "vs-sm": "8",
                    "vs-xs": "12",
                  },
                },
                [
                  _c("div", { staticClass: "user-bio" }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.project.analyst_information.about)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }