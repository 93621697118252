<template>
  <vs-popup title="Globalwork Dice" :active.sync="modalActive">
    <div style="text-align:center;">
      <h2>Generando Reporte</h2>
    </div>
    <div class="pro-bar-container" style="margin-top: 8%;">
      <div class="pro-bar pro-bar-width" data-pro-bar-percent="80">
        <div class="pro-bar-candy"></div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
export default {
  props:['modalActive']
}
</script>

<style>
.pro-bar-container {
  background: #ccc;
  height: 1em;
  overflow: hidden;
  width: 100%;
  margin-top: 24%;
}

.pro-bar {
  background: #5F0091;
  height: inherit;
}

.pro-bar-width{
  /* Here is where you specify the width of ur progress-bar */
  width: 100%;
}

.pro-bar-candy {
  animation: progress .6s linear infinite;
  /* Don't touch this */
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.25) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.25) 50%,
    rgba(255, 255, 255, 0.25) 75%,
    transparent 75%,
    transparent);
  /* Don't touch this */
  background-repeat: repeat-x;
  /* The size of the bars must match the background-position in the @keyframes */
  background-size: 2em 2em;
  height: inherit;
  width: 100%;
}

@keyframes progress {
  to { background-position: 2em 0; }
}

</style>