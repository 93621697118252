<template>
  <div>
    <vx-card>
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12" ></vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="4" vs-xs="12">
          <div class="text-center">
            <h4 class="">{{project.analyst_information.title}}</h4>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12"></vs-col>
      </vs-row>      
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="12">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div class="vx-card__body">
                <div class="flex items-center">
                  <div>
                    <div class="con-vs-avatar m-0 45px" style="width: 45px; height: 45px; background: rgb(195, 195, 195);">
                      <div class="con-img vs-avatar--con-img"><img src="https://pixinvent.com/demo/vuesax-vuejs-admin-dashboard-template/demo-1/img/user-01.d09da647.jpg" alt=""></div>
                    </div>
                  </div>
                  <div class="ml-4">
                  <h6>{{project.analyst_information.name}}</h6><small>{{humanizeDate(project.analyst_information.created_at)}}, {{project.analyst_information.city_name}}</small></div>
                </div>
                <div class="mt-5">
                  <h6>Email:</h6>
                  <p>{{project.analyst_information.email}}</p>
                </div>
              </div>
            </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="12">
          <div class="user-bio">
            <p>{{project.analyst_information.about}}</p>
          </div>                    
        </vs-col>
      </vs-row>
    </vx-card>    
  </div>

</template>

<script>
export default {
  props: {
    project: Object
  },
  data (){
    return{
      clientLogo: ''
    }
  },
  methods: {
    humanizeDate(date){
      var arrayDate = date.split("T")
      var stringDate = arrayDate[0]
      return stringDate
    },
  },
  created(){

  },
  mounted(){

  },
  components:{

  }
}
</script>

<style>
  .table-title{
    /* background-color: #fdf8ff; */
    font-weight: bold;
  }
</style>