var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "text-center" }, [
            _c("h4", [_vm._v("Restricciones en la información")]),
          ]),
          _c(
            "vs-table",
            { attrs: { stripe: "", noDataText: "" } },
            [
              _vm._l(
                _vm.project.observation_restrictions,
                function (restriction, index) {
                  return _c(
                    "vs-tr",
                    { key: index },
                    [
                      _c("vs-th", [_vm._v("Fecha")]),
                      _c("vs-td", [
                        _vm._v(
                          _vm._s(_vm.humanizeDate(restriction.created_at))
                        ),
                      ]),
                      _c("vs-th", [_vm._v("Observación")]),
                      _c("vs-td", [_vm._v(_vm._s(restriction.observation))]),
                    ],
                    1
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }