<template>
  <div>
    <vx-card>                  
      <div class="text-center">
        <h4>Validación DataCrédito</h4>
      </div>    

      <vs-table :data="creditValidation">
        <template slot="header">
          <!-- <h3>
            Fecha
          </h3> -->
        </template>
        <template slot="thead">

          <vs-th>
            Verificar DataCrédito
          </vs-th>

          <vs-th>
            Archivo
          </vs-th>

          <vs-th>
            Resumen
          </vs-th>          

          <vs-th>
            Observaciones
          </vs-th>              
        </template>

        <template>
          <vs-tr >

            <vs-td v-if="project.credit_validation" width="10%">
              Si
            </vs-td>

            <vs-td v-if="!project.credit_validation" width="10">
              No
            </vs-td>            

            <vs-td width="15%">
              <a @click="validateUrl(project.credit_archive)" target="_blank" type="button" name="button" class="vs-component vs-button" style="background: linear-gradient(30deg, rgb(115, 103, 240) 0%, rgb(206, 159, 252) 100%);">
                <span class="vs-button-text vs-button--text">Ver informe</span>
              </a>                          
            </vs-td>

            <vs-td width="15%">
              <a v-bind:href="project.credit_photo" target="_blank" type="button" name="button" class="vs-component vs-button" style="background: linear-gradient(30deg, rgb(115, 103, 240) 0%, rgb(206, 159, 252) 100%);">
                <span class="vs-button-text vs-button--text">Resumen</span>
              </a>                          
            </vs-td>            

            <vs-td width="60%">
              {{project.credit_observations}}
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>          

    </vx-card>                  
  </div>
</template>

<script>
export default {
  props: {
    project: Object
  },
  data (){
    return{

    }
  },
  methods: {
    validateUrl(url){
      if(url.includes('/system')) {
        let newUrl = url.split('/')
        newUrl.splice(newUrl.indexOf('system'),1)
        newUrl = newUrl.join('/')
        window.open(`${newUrl}`,'_blank')
      }else{
        window.open(`${url}`,'_blank')
      }
    }
  },
  created(){

  },
  mounted(){

  },
  components:{

  }
}
</script>

<style>

</style>