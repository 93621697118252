var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-card" }, [
      _c(
        "div",
        {
          staticClass: "vx-card__collapsible-content vs-con-loading__container",
        },
        [
          _c("br"),
          _vm._m(0),
          _c("br"),
          _c("vs-list", [
            _c("li", { staticClass: "item-custom item-antecedent" }, [
              _c(
                "b",
                { staticClass: "title-stars" },
                [
                  _c("vs-icon", {
                    attrs: { icon: "schedule", color: "warning" },
                  }),
                ],
                1
              ),
              _vm._v(
                "\n        La información no ha sido obtenida aún\n      "
              ),
            ]),
            _c("li", { staticClass: "item-custom item-antecedent" }, [
              _c(
                "b",
                { staticClass: "title-stars" },
                [
                  _c("vs-icon", {
                    attrs: { icon: "report_problem", color: "danger" },
                  }),
                ],
                1
              ),
              _vm._v(
                "\n        Hay novedades que te sugerimos revisar\n      "
              ),
            ]),
            _c("li", { staticClass: "item-custom item-antecedent" }, [
              _c(
                "b",
                { staticClass: "title-stars" },
                [_c("vs-icon", { attrs: { icon: "done", color: "success" } })],
                1
              ),
              _vm._v("\n        No se presentan registros negativos\n      "),
            ]),
          ]),
          _c("br"),
          _c(
            "vs-row",
            { attrs: { "vs-w": "12" } },
            [
              _c("vs-col", {
                attrs: {
                  "vs-type": "flex",
                  "vs-justify": "center",
                  "vs-align": "center",
                  "vs-lg": "1",
                  "vs-sm": "1",
                  "vs-xs": "1",
                },
              }),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "10",
                    "vs-sm": "10",
                    "vs-xs": "10",
                  },
                },
                [
                  _vm._v(
                    "\n        A continuación, verás si existen registros negativos del candidato en las bases de datos consultadas\n      "
                  ),
                ]
              ),
              _c("vs-col", {
                attrs: {
                  "vs-type": "flex",
                  "vs-justify": "center",
                  "vs-align": "center",
                  "vs-lg": "1",
                  "vs-sm": "1",
                  "vs-xs": "10",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full lg:w-1/1 mb-base" },
              [
                _c(
                  "vs-table",
                  { attrs: { stripe: "", noDataText: "" } },
                  [
                    _c("template", { slot: "header" }),
                    _c(
                      "template",
                      { slot: "thead" },
                      [_c("vs-th"), _c("vs-th")],
                      1
                    ),
                    _vm._l(
                      _vm.project.judicial_verification.filter(function (
                        judicial_verification
                      ) {
                        return judicial_verification.photo == true
                      }),
                      function (judicial_verification, index) {
                        return _c(
                          "vs-tr",
                          { key: index },
                          [
                            _c("vs-td", { attrs: { width: "90%" } }, [
                              _c("p", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(judicial_verification.name) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                            _c("vs-td", { attrs: { width: "10%" } }, [
                              judicial_verification.photo
                                ? _c(
                                    "span",
                                    [
                                      judicial_verification.verification
                                        ? _c("vs-icon", {
                                            attrs: {
                                              size: "small",
                                              icon: "report_problem",
                                              color: "danger",
                                            },
                                          })
                                        : _vm._e(),
                                      !judicial_verification.verification
                                        ? _c("vs-icon", {
                                            attrs: {
                                              size: "small",
                                              icon: "done",
                                              color: "success",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("h4", [_vm._v("Verificación Judicial")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }