<template>
	<div>
		<vx-card>        
      <div class="text-center">
        <h4>Restricciones en la información</h4>
      </div>
			<vs-table stripe noDataText="">
				<template>
					<vs-tr v-for="(restriction, index) in project.observation_restrictions" :key="index">
						<vs-th>Fecha</vs-th>
						<vs-td>{{humanizeDate(restriction.created_at)}}</vs-td>
						<vs-th>Observación</vs-th>
						<vs-td>{{restriction.observation}}</vs-td>
					</vs-tr>
				</template>
			</vs-table>
		</vx-card>
	</div>
</template>

<script>
export default {
	name:'ProjectRestrictions',
	props:{
		project:{type:Object, required:true}
	},
	methods:{
		humanizeDate(date){
      var arrayDate = date.split("T")
      var stringDate = arrayDate[0]
      var hour = arrayDate[1].split(".")[0]
      return stringDate + ", " + hour
    }
	}
}
</script>

<style>

</style>