var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", [
        _c("div", { staticClass: "text-center" }, [
          _c("h4", [_vm._v("Concepto general")]),
        ]),
        _c("br"),
        _c("div", { staticClass: "text-center" }, [
          _c(
            "h1",
            {
              style:
                "color: " +
                _vm.colorConceptGeneralText(this.project.overall_final),
            },
            [_vm._v(_vm._s(this.project.overall_final_name))]
          ),
        ]),
        _c("br"),
        _c(
          "h3",
          {
            staticClass: "mb-3 text-center",
            style:
              "color: " +
              _vm.colorConceptGeneralText(this.project.overall_final),
          },
          [
            _vm._v(
              "\n      " + _vm._s(this.project.overall_final_detail) + "\n    "
            ),
          ]
        ),
        _c("br"),
        _c("div", { staticClass: "vx-row" }, [
          _c("div", { staticClass: "vx-col w-full lg:w-1/3 mb-base" }),
          _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
            _vm._l(this.project.general_concepts, function (data, index) {
              return _c("div", { key: index }, [
                data.value > 0 && data.name != "Concepto General"
                  ? _c(
                      "div",
                      {
                        staticClass: "vx-row",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center",
                          padding: "5px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                          [
                            _c("h6", { staticClass: "text-left" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(data.name) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "vx-col w-full lg:w-1/2 mb-base",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _c("star-rating", {
                              attrs: {
                                "read-only": "",
                                "inactive-color": "rgb(153, 153, 153)",
                                "active-color": _vm.colorConceptGeneral(
                                  data.value
                                ),
                                rating: data.value,
                                "max-rating": 5,
                                "star-size": 30,
                              },
                            }),
                            _c("feather-icon", {
                              staticStyle: { "margin-left": "13px" },
                              attrs: { icon: "InfoIcon" },
                              on: {
                                mouseover: function ($event) {
                                  return _vm.viewAlert("message" + index)
                                },
                                mouseout: function ($event) {
                                  return _vm.hiddenAlert("message" + index)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              visibility: "hidden",
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center",
                              position: "relative",
                              left: "32%",
                              "margin-bottom": "-69%",
                              bottom: "13vh",
                            },
                            attrs: { id: "message" + index },
                          },
                          [
                            _c("div", {
                              staticStyle: {
                                background: "#FAF8FF",
                                border: "1px solid #D1C9E0",
                                "box-sizing": "border-box",
                                "border-radius": "2.5px",
                                transform: "rotate(45deg)",
                                position: "relative",
                                left: "87%",
                                bottom: "82px",
                                width: "12px",
                                height: "12px",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  background: "#FAF8FF",
                                  border: "1px solid #D1C9E0",
                                  position: "relative",
                                  left: "85%",
                                  bottom: "83px",
                                  "box-sizing": "border-box",
                                  "border-radius": "5px",
                                  width: "314px",
                                  "word-break": "break-word",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      margin: "12px",
                                      "font-weight": "500",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(data.value) +
                                        " " +
                                        _vm._s(
                                          data.value > 1
                                            ? "estrellas"
                                            : "estrella"
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      margin: "15px",
                                      "text-align": "left",
                                      "font-size": "13px",
                                      "line-height": "15px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(data.observation) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "vx-col w-full lg:w-1/3 mb-base" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }