var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "vs-list--header title-list vs-header-list-primary" },
      [
        _c("div", { staticClass: "list-titles" }, [
          _c("div", { staticClass: "vs-list--title" }, [
            _vm._v(_vm._s(_vm.academicReference.name_institution)),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "vs-list list-academic-details" },
      _vm._l(_vm.steps, function (step) {
        return _c(
          "div",
          {
            key: step.key,
            staticClass: "vs-list--item item-list step-checklist",
          },
          [
            _vm.academicReference.checklist[step.key].status > 0
              ? [
                  _c(
                    "div",
                    { staticClass: "vs-list--icon" },
                    [
                      _vm.academicReference.checklist[step.key].status == 1
                        ? _c("vs-icon", {
                            staticClass: "icon-step",
                            attrs: { icon: "alarm", color: "warning" },
                          })
                        : _vm._e(),
                      _vm.academicReference.checklist[step.key].status == 2
                        ? _c("vs-icon", {
                            staticClass: "icon-step",
                            attrs: { icon: "done", color: "success" },
                          })
                        : _vm._e(),
                      _vm.academicReference.checklist[step.key].status == 3
                        ? _c("vs-icon", {
                            staticClass: "icon-step",
                            attrs: { icon: "clear", color: "danger" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "list-titles" }, [
                    _c("div", { staticClass: "vs-list--title title-step" }, [
                      _vm._v(_vm._s(step.text)),
                    ]),
                    _c("div", { staticClass: "vs-list--subtitle" }, [
                      _vm._v(
                        _vm._s(
                          step.textInformation[
                            _vm.academicReference.checklist[step.key].status
                          ]
                        )
                      ),
                    ]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }