<template>
  <div>
    <vx-card v-if="project != {}">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12" >
          <div class="con-vs-avatar mx-auto my-6 block 160px" style="width: 40%; height: 50%;" v-if="project.quality">
            <div class="con-img vs-avatar--con-img" style="border-radius: 0% !important;"><img src="./../../assets/images/quality.png" alt=""></div>
          </div>
          <div style="margin-left: 1rem;" v-if="project.quality">
            <p for="" style="color: rgb(95, 0, 144); font-weight: 700; font-size: 10px">Verificado por el equipo de calidad</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="4" vs-xs="12">
          <div class="text-center" v-if="project.data_basic != null">
            <h4 style="color: #5F0090">{{this.project.data_basic.person_full_name}}</h4>
            <p class="text-grey">Cédula: {{this.project.data_basic.person_id}}</p>
            <p class="text-grey">{{this.project.data_basic.current_activity}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12">
          <div class="con-vs-avatar mx-auto my-6 block 80px" style="width: 80px; height: 80px; background: rgb(195, 195, 195);">
            <div class="con-img vs-avatar--con-img"><img v-bind:src="clientLogo" alt=""></div>
          </div>
        </vs-col>
      </vs-row>
      <div class="text-center" v-if="project.overall_final_name != ''">
        <h1 v-bind:style="'color: ' + colorConceptGeneralText(this.project.overall_final)">{{this.project.overall_final_name}}</h1>
      </div>
      <br>
      <div class="vx-row" v-if="project.overall_final_name != '' && project.data_basic != null">

        <div class="vx-col md:w-1/6 w-full">
          <h6 class="table-title">Fecha de creación:</h6>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          {{this.project.data_basic.created_at}}
        </div>

        <div class="vx-col md:w-1/6 w-full">
          <h6 class="table-title">Creado por:</h6>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          {{this.project.data_basic.client_user_name}}
        </div>

        <div class="vx-col md:w-1/6 w-full ">
          <h6 class="table-title">Ciudad:</h6>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          {{this.project.data_basic.city_name}}
        </div>

      </div>
      <br>
      <div class="vx-row" v-if="project.data_basic != null">
        <div class="vx-col md:w-1/6 w-full">
          <h6 class="table-title">Finalizado:</h6>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          {{this.project.data_basic.delivered_at}}
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <h6 class="table-title">Tipo de proceso:</h6>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          {{this.project.project_type_name}} {{this.project.poly_type_name}}
        </div>
      </div>

      <br>
    </vx-card>
  </div>

</template>

<script>
export default {
  props: {
    project: Object,
    userInfo:Object
  },
  data (){
    return{
      clientLogo: ''
    }
  },

  methods: {
    colorConceptGeneral(value){
      var color = "rgb(153, 153, 153)"
      if(value >= 4){
        color = "rgb(80, 181, 75)"
      }
      if(value == 3){
        color = "rgb(250, 224, 2)"
      }
      if(value <= 2){
        color = "rgb(201, 74, 23)"
      }
      return color;
    },
    colorConceptGeneralText(value){
      var color = "rgb(153, 153, 153)"
      if(value == 0){
        color = "rgb(201, 74, 23)"
      }
      if(value == 1){
        color = "rgb(80, 181, 75)"
      }
      if(value == 2){
        color = '#FF9F43'
      }
      return color;
    },
    activeUserImg() {
      if(this.userInfoObject != undefined){
         var photoUrl = this.userInfoObject.photoUrl
         var photo = ''
         if(photoUrl != null){
             photoUrl = photoUrl.replace('//', 'https://')
             photoUrl = photoUrl.replace('development', 'production')
             photo = photoUrl;
             return photo;
         }
      }

    }
  },
  created(){
    this.clientLogo = this.activeUserImg();
  },
  mounted(){

  },
  computed: {
    userInfoObject:{
      get(){
        return this.userInfo
      },
      set(val){
        this.$emit('update',val)
      }
    }
  },
  components:{

  }
}
</script>

<style>
  .table-title{
    /* background-color: #fdf8ff; */
    font-weight: bold;
  }
</style>
