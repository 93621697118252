export default {
  data() {
    return {
      steps: [ 
        { 
          key: 'documents', 
          text: 'Documentos para validación', 
          textInformation: {
            '0': 'No inicializado',
            '1': 'El aspirante y/o la empresa aún no han adjuntado los documentos pertinentes para verificar las referencias del candidato.', // ok
            '2': 'El aspirante y/o la empresa adjuntaron todos los documentos pertinentes para verificar las referencias del candidato.' // ok
          }
        },
        { 
          key: 'institution_response', 
          text: 'Respuesta de la institución', 
          textInformation: {
            '0': 'No inicializado',
            '1': 'Hasta el momento estamos esperando respuesta formal por parte de la institución.', // ok
            '2': 'Se recibió respuesta formal por parte de la institución y se finalizó la verificación.', // ok
            '3': 'No hubo una respuesta formal por parte de la institución para la verificación de información.' // ok
          }
        },
        { 
          key: 'graphology',
           text: 'Grafología', 
           textInformation: {
            '0': 'No inicializado',
            '1': 'Aún estamos verificando los documentos presentados por el aspirante con técnicas grafológicas.', // ok
            '2': 'Se verificaron los documentos presentados por el aspirante a través de técnicas grafológicas.', // ok
            '3': 'No se lograron verificar los documentos del aspirante.' // ok
          }}
        ,
        { 
          key: 'certified', 
          text: 'Verificación de certificados', 
          textInformation: {
            '0': 'No inicializado',
            '1': 'Aún estamos verificando los certificados de exámenes de estado previos a la graduación y/o certificados de convalidación, homologación y presentación de títulos en el extranjero.', // ok
            '2': 'Se verificaron los certificados de exámenes de estado previos a la graduación y/o certificados de convalidación, homologación y presentación de títulos en el extranjero.', // ok
            '3': 'No se lograron verificar los certificados de exámenes de estado previos a la graduación y/o certificados de convalidación, homologación y presentación de títulos en el extranjero.' // ok
          }
        },
      ]
    }
  },
}
