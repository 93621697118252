<template>
  <div>
    <vx-card>        
      <div class="text-center">
        <h4>Observaciones realizadas por el equipo de operaciones</h4>
      </div> 
      <br> 
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="9" vs-sm="9" vs-xs="9">
          Haz clic en cada opción para conocer lo que nuestro equipo encontró en las verificaciones
        </vs-col>
      </vs-row> 
      <br>
      <vs-progress indeterminate color="primary" v-if="progressReport"></vs-progress> 
      <template v-if="project.observations != null">       
        <vs-tabs v-if="Object.keys(project.observations).length > 0">          
          <div v-for="(observations, index_observation) in project.observations" :key="index_observation">
            <vs-tab v-bind:label="`Equipo ${observations[0].type}`" v-if="showObservations(observations[0].type)">
              <vs-table noDataText="">
                <template slot="header">
                </template>
                <template slot="thead">
                  <vs-th>
                    Fecha
                  </vs-th>
                  <vs-th>
                    Observación
                  </vs-th>
                </template>

                <template>
                  <vs-tr v-for="(observation, index_observation) in observations" :key="index_observation" >
                    <vs-td :data="observation.created_at"> 
                      {{ humanizeDate(observation.created_at) }}                 
                    </vs-td>

                    <vs-td :data="observation.created_at">
                      {{observation.observation}}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>          
            </vs-tab>
            <vs-tab label="Visita domiciliaria" v-if="project.project_type === 0 || project.project_type === 6 || project.project_type === 11">
              <div class="vx-row">
                <div v-for="(visit_concept_item, index_visit_concept) in project.visit_concept" :key="index_visit_concept" class="vx-col w-full lg:w-1/3 mb-base">          
                  <b>{{Object.keys(visit_concept_item)[0]}}: </b> {{visit_concept_item[Object.keys(visit_concept_item)]}}
                </div>
              </div>
            </vs-tab>              
          </div>
        </vs-tabs>
      </template>
    </vx-card>
    <modal-message :modalActive="progressReport" />                  
  </div>
</template>

<script>
import api from '../../mixins/api.js'
import { mapGetters } from 'vuex'
import ModalMessage from './../ModalMessage'
export default {
  mixins: [api],
  props: {
    project: Object
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data (){
    return{
      progressReport: false
    }
  },
  methods: {
    showObservations(type){
      console.log(type)
      let array = ['Laboral', 'Académico', 'Personal']
      return !array.includes(type)
    },
    humanizeDate(date){
      var arrayDate = date.split("T")
      var stringDate = arrayDate[0]
      var hour = arrayDate[1].split(".")[0]
      return stringDate + ", " + hour
    },
    chunk(array, size) {
      const chunked_arr = [];      
      if(array != null){
        for (let i = 0; i < array.length; i++) {
          const last = chunked_arr[chunked_arr.length - 1];
          if (!last || last.length === size) {
            chunked_arr.push([array[i]]);
          } else {
            last.push(array[i]);
          }
        }
      }
      return chunked_arr;
    },
    async linkToReport(){
      let fileName = this.project.project_person_full_name + " - " + this.project.project_type_name + " - " + this.project.person_id
      console.log(fileName)
      await this.linkReport(this.project.id,this.userInfo.user.id,fileName)
    },
    async linkToResumen(){
      let fileName = this.project.project_person_full_name + " - " + this.project.project_type_name + " - " + this.project.person_id
      console.log(this.userInfo)
      await this.linkResumen(this.project.id,this.userInfo.user.id,fileName)
    }
},
  created(){

  },
  mounted(){

  },
  components:{
    ModalMessage
  }
}
</script>

<style>

</style>
