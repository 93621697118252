var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      attrs: { title: "Globalwork Dice", active: _vm.modalActive },
      on: {
        "update:active": function ($event) {
          _vm.modalActive = $event
        },
      },
    },
    [
      _c("div", { staticStyle: { "text-align": "center" } }, [
        _c("h2", [_vm._v("Generando Reporte")]),
      ]),
      _c(
        "div",
        {
          staticClass: "pro-bar-container",
          staticStyle: { "margin-top": "8%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "pro-bar pro-bar-width",
              attrs: { "data-pro-bar-percent": "80" },
            },
            [_c("div", { staticClass: "pro-bar-candy" })]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }