var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _vm.project.forms != null
            ? [
                Object.keys(_vm.project.forms).length > 0
                  ? _c(
                      "vs-tabs",
                      _vm._l(_vm.project.forms, function (form, index_form) {
                        return _c(
                          "vs-tab",
                          { key: index_form, attrs: { label: form.title } },
                          [
                            _c(
                              "div",
                              { staticClass: "con-tab-ejemplo" },
                              [
                                _c(
                                  "vs-table",
                                  { attrs: { stripe: "", noDataText: "" } },
                                  [
                                    _vm._l(
                                      _vm.chunk(form.fields, 3),
                                      function (row, index_row) {
                                        return _c(
                                          "vs-tr",
                                          { key: index_row },
                                          _vm._l(
                                            row,
                                            function (col, index_col) {
                                              return _c(
                                                "vs-td",
                                                {
                                                  key: index_col,
                                                  attrs: { width: "33%" },
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "table-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          Object.keys(col)[0]
                                                        ) + ":"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        col[Object.keys(col)[0]]
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                form.title == "Vivienda"
                                  ? _c(
                                      "GmapMap",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "400px",
                                        },
                                        attrs: {
                                          center: _vm.getCoordinates(form),
                                          zoom: 15,
                                          "map-type-id": "roadmap",
                                        },
                                      },
                                      [
                                        _c("GmapMarker", {
                                          attrs: {
                                            position: _vm.getCoordinates(form),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("br"),
      _c(
        "vx-card",
        [
          _vm.project.relations
            ? [
                Object.keys(_vm.project.relations).length > 0
                  ? _c(
                      "vs-tabs",
                      _vm._l(
                        _vm.project.relations,
                        function (form, index_form) {
                          return _c(
                            "vs-tab",
                            {
                              key: index_form,
                              attrs: { label: Object.keys(form)[0] },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "con-tab-ejemplo" },
                                [
                                  _vm._l(
                                    Object.values(
                                      form[Object.keys(form)[0]]
                                    )[0],
                                    function (data_refs, index_refs) {
                                      return _c(
                                        "div",
                                        { key: index_refs, staticClass: "ref" },
                                        [
                                          Object.keys(form) ==
                                          "Referencias académicas"
                                            ? _c("academic-details-show", {
                                                attrs: {
                                                  project: _vm.project,
                                                  academicReferenceId:
                                                    data_refs.fields[0][
                                                      "Identificador"
                                                    ],
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "vs-table",
                                            {
                                              attrs: {
                                                stripe: "",
                                                noDataText: "",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.chunk(data_refs.fields, 3),
                                                function (ref, index_ref) {
                                                  return _c(
                                                    "vs-tr",
                                                    { key: index_ref },
                                                    _vm._l(
                                                      ref,
                                                      function (
                                                        data,
                                                        index_col
                                                      ) {
                                                        return _c(
                                                          "vs-td",
                                                          {
                                                            key: index_col,
                                                            attrs: {
                                                              width: "33%",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "h6",
                                                              {
                                                                staticClass:
                                                                  "table-title",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    Object.keys(
                                                                      data
                                                                    )[0]
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            Object.keys(
                                                              data
                                                            )[0] ==
                                                            "Observaciones"
                                                              ? [
                                                                  _c(
                                                                    "textarea",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              data[
                                                                                Object.keys(
                                                                                  data
                                                                                )[0]
                                                                              ],
                                                                            expression:
                                                                              "data[Object.keys(data)[0]]",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "textarea-read-only",
                                                                      attrs: {
                                                                        cols: "60",
                                                                        rows: "10",
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            data[
                                                                              Object.keys(
                                                                                data
                                                                              )[0]
                                                                            ],
                                                                        },
                                                                      on: {
                                                                        input:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            if (
                                                                              $event
                                                                                .target
                                                                                .composing
                                                                            ) {
                                                                              return
                                                                            }
                                                                            _vm.$set(
                                                                              data,
                                                                              Object.keys(
                                                                                data
                                                                              )[0],
                                                                              $event
                                                                                .target
                                                                                .value
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : [
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        data[
                                                                          Object.keys(
                                                                            data
                                                                          )[0]
                                                                        ]
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ],
                                                          ],
                                                          2
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c("vs-divider"),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm.observationTeam(Object.keys(form)) != null
                                    ? [
                                        Object.keys(
                                          _vm.observationTeam(Object.keys(form))
                                        ).length > 0
                                          ? _c(
                                              "vs-tabs",
                                              [
                                                _vm._l(
                                                  _vm.observationTeam(
                                                    Object.keys(form)
                                                  ),
                                                  function (
                                                    observations,
                                                    index_observation
                                                  ) {
                                                    return _c(
                                                      "vs-tab",
                                                      {
                                                        key: index_observation,
                                                        attrs: {
                                                          label:
                                                            "Observaciones Equipo " +
                                                            observations[0]
                                                              .type,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "vs-table",
                                                          {
                                                            attrs: {
                                                              noDataText: "",
                                                            },
                                                          },
                                                          [
                                                            _c("template", {
                                                              slot: "header",
                                                            }),
                                                            _c(
                                                              "template",
                                                              { slot: "thead" },
                                                              [
                                                                _c("vs-th", [
                                                                  _vm._v(
                                                                    "\n                        Fecha\n                      "
                                                                  ),
                                                                ]),
                                                                _c("vs-th", [
                                                                  _vm._v(
                                                                    "\n                        Observación\n                      "
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._l(
                                                              observations,
                                                              function (
                                                                observation,
                                                                index_observation
                                                              ) {
                                                                return _c(
                                                                  "vs-tr",
                                                                  {
                                                                    key: index_observation,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "vs-td",
                                                                      {
                                                                        attrs: {
                                                                          data: observation.created_at,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm.humanizeDate(
                                                                                observation.created_at
                                                                              )
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "vs-td",
                                                                      {
                                                                        attrs: {
                                                                          data: observation.created_at,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              observation.observation
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _vm.project.project_type ===
                                                  0 ||
                                                _vm.project.project_type ===
                                                  6 ||
                                                _vm.project.project_type === 11
                                                  ? _c(
                                                      "vs-tab",
                                                      {
                                                        attrs: {
                                                          label:
                                                            "Visita domiciliaria",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "vx-row",
                                                          },
                                                          _vm._l(
                                                            _vm.project
                                                              .visit_concept,
                                                            function (
                                                              visit_concept_item,
                                                              index_visit_concept
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: index_visit_concept,
                                                                  staticClass:
                                                                    "vx-col w-full lg:w-1/3 mb-base",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        Object.keys(
                                                                          visit_concept_item
                                                                        )[0]
                                                                      ) + ": "
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        visit_concept_item[
                                                                          Object.keys(
                                                                            visit_concept_item
                                                                          )
                                                                        ]
                                                                      ) +
                                                                      "\n                    "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("br"),
      _c(
        "vx-card",
        [
          _vm.project.pila_info !== null
            ? [
                _c(
                  "vs-tabs",
                  [
                    _c("vs-tab", { attrs: { label: "Histórico laboral" } }, [
                      _vm.project.pila_info.has_pila_periods
                        ? _c(
                            "div",
                            { staticClass: "con-tab-ejemplo observation-card" },
                            [
                              _c("div", { staticClass: "observation-text" }, [
                                _c("span", [
                                  _vm._v(
                                    "\n                    Tras revisar en la base de datos PILA de enlace operativo encontramos información adicional confiable sobre el histórico laboral del candidato\n                    "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      " durante los últimos 36 meses, correspondiente al período de recopilación de datos de PILA."
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "observation-text" },
                                [
                                  _c(
                                    "vs-row",
                                    [
                                      _c(
                                        "vs-col",
                                        {
                                          attrs: {
                                            "vs-type": "flex",
                                            "vs-justify": "start",
                                            "vs-align": "center",
                                            "vs-w": "5",
                                          },
                                        },
                                        [_c("strong", [_vm._v("Empresa:")])]
                                      ),
                                      _c(
                                        "vs-col",
                                        {
                                          attrs: {
                                            "vs-type": "flex",
                                            "vs-justify": "start",
                                            "vs-align": "center",
                                            "vs-w": "5",
                                          },
                                        },
                                        [_c("strong", [_vm._v("Periodo:")])]
                                      ),
                                      _c(
                                        "vs-col",
                                        {
                                          attrs: {
                                            "vs-type": "flex",
                                            "vs-justify": "start",
                                            "vs-align": "center",
                                            "vs-w": "2",
                                          },
                                        },
                                        [_c("strong", [_vm._v("Duración:")])]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("br"),
                                  _vm._l(
                                    _vm.project.pila_info.pila_periods,
                                    function (pila_period, i) {
                                      return _c(
                                        "vs-row",
                                        {
                                          key: i,
                                          staticStyle: {
                                            "margin-bottom": "2%",
                                          },
                                        },
                                        [
                                          i < 5
                                            ? _c(
                                                "vs-col",
                                                {
                                                  attrs: {
                                                    "vs-type": "flex",
                                                    "vs-justify": "start",
                                                    "vs-align": "top",
                                                    "vs-w": "4",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        pila_period.company
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          i < 5
                                            ? _c(
                                                "vs-col",
                                                {
                                                  attrs: {
                                                    "vs-type": "",
                                                    "vs-justify": "",
                                                    "vs-align": "",
                                                    "vs-w": "8",
                                                  },
                                                },
                                                _vm._l(
                                                  pila_period.periods_per_company,
                                                  function (period, j) {
                                                    return _c(
                                                      "vs-row",
                                                      { key: j },
                                                      [
                                                        _c(
                                                          "vs-col",
                                                          {
                                                            attrs: {
                                                              "vs-type": "flex",
                                                              "vs-justify": "",
                                                              "vs-align": "",
                                                              "vs-w": "9",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                " +
                                                                _vm._s(
                                                                  period.start_month
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  period.start_year
                                                                ) +
                                                                " a " +
                                                                _vm._s(
                                                                  period.end_month
                                                                    ? period.end_month
                                                                    : "No reporta"
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  period.end_year
                                                                ) +
                                                                "\n                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-col",
                                                          {
                                                            attrs: {
                                                              "vs-type": "flex",
                                                              "vs-justify": "",
                                                              "vs-align": "",
                                                              "vs-w": "3",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                " +
                                                                _vm._s(
                                                                  period.months
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  period.months ==
                                                                    "1"
                                                                    ? "Mes"
                                                                    : "Meses "
                                                                ) +
                                                                "\n                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          )
                        : _c("div", [
                            _c("div", { staticClass: "observation-text" }, [
                              _c("span", [
                                _c("strong", [
                                  _vm._v(
                                    "No se ha encontrado información adicional sobre el histórico laboral "
                                  ),
                                ]),
                                _vm._v(
                                  "\n                    del candidato en la base de datos PILA de enlace operativo.\n                  "
                                ),
                              ]),
                            ]),
                          ]),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }