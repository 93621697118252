var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "text-center" }, [
            _c("h4", [_vm._v("Validación DataCrédito")]),
          ]),
          _c(
            "vs-table",
            { attrs: { data: _vm.creditValidation } },
            [
              _c("template", { slot: "header" }),
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", [
                    _vm._v("\n          Verificar DataCrédito\n        "),
                  ]),
                  _c("vs-th", [_vm._v("\n          Archivo\n        ")]),
                  _c("vs-th", [_vm._v("\n          Resumen\n        ")]),
                  _c("vs-th", [_vm._v("\n          Observaciones\n        ")]),
                ],
                1
              ),
              [
                _c(
                  "vs-tr",
                  [
                    _vm.project.credit_validation
                      ? _c("vs-td", { attrs: { width: "10%" } }, [
                          _vm._v("\n            Si\n          "),
                        ])
                      : _vm._e(),
                    !_vm.project.credit_validation
                      ? _c("vs-td", { attrs: { width: "10" } }, [
                          _vm._v("\n            No\n          "),
                        ])
                      : _vm._e(),
                    _c("vs-td", { attrs: { width: "15%" } }, [
                      _c(
                        "a",
                        {
                          staticClass: "vs-component vs-button",
                          staticStyle: {
                            background:
                              "linear-gradient(30deg, rgb(115, 103, 240) 0%, rgb(206, 159, 252) 100%)",
                          },
                          attrs: {
                            target: "_blank",
                            type: "button",
                            name: "button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.validateUrl(_vm.project.credit_archive)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "vs-button-text vs-button--text" },
                            [_vm._v("Ver informe")]
                          ),
                        ]
                      ),
                    ]),
                    _c("vs-td", { attrs: { width: "15%" } }, [
                      _c(
                        "a",
                        {
                          staticClass: "vs-component vs-button",
                          staticStyle: {
                            background:
                              "linear-gradient(30deg, rgb(115, 103, 240) 0%, rgb(206, 159, 252) 100%)",
                          },
                          attrs: {
                            href: _vm.project.credit_photo,
                            target: "_blank",
                            type: "button",
                            name: "button",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "vs-button-text vs-button--text" },
                            [_vm._v("Resumen")]
                          ),
                        ]
                      ),
                    ]),
                    _c("vs-td", { attrs: { width: "60%" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.project.credit_observations) +
                          "\n          "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }