<template>
  <div>
    <div class="vx-card">          
      <div class="vx-card__collapsible-content vs-con-loading__container">
      <br>
      <div class="text-center">
        <h4>Verificación Judicial</h4>        
      </div>        
      <br>
      <vs-list>
        <li class="item-custom item-antecedent">
          <b class="title-stars">
            <vs-icon icon="schedule" color="warning"></vs-icon>
          </b>
          La información no ha sido obtenida aún
        </li>            
        <li class="item-custom item-antecedent">
          <b class="title-stars">
            <vs-icon icon="report_problem" color="danger"></vs-icon>
          </b>
          Hay novedades que te sugerimos revisar
        </li>
        <li class="item-custom item-antecedent">
          <b class="title-stars">
            <vs-icon icon="done" color="success"></vs-icon>
          </b>
          No se presentan registros negativos
        </li>
      </vs-list>      
      <br>
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="1" vs-xs="1" >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="10">
          A continuación, verás si existen registros negativos del candidato en las bases de datos consultadas
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="1" vs-xs="10">
        </vs-col>
      </vs-row>      
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/1 mb-base">        
          <vs-table stripe noDataText="">
            <template slot="header">
              <!-- <h3>
                Fecha
              </h3> -->
            </template>
            <template slot="thead">

              <vs-th>

              </vs-th>

              <vs-th>

              </vs-th>

            </template>

            <template>
              <vs-tr v-for="(judicial_verification, index) in project.judicial_verification.filter(judicial_verification => judicial_verification.photo == true)" :key="index" >
                <vs-td width="90%">
                  <p>
                    {{judicial_verification.name}}
                  </p>
                </vs-td>              

                <vs-td width="10%">
                  <span v-if="judicial_verification.photo">                    
                    <vs-icon size="small" icon="report_problem" v-if="judicial_verification.verification" color="danger"></vs-icon>
                    <vs-icon size="small" icon="done" v-if="!judicial_verification.verification" color="success"></vs-icon>
                  </span>
                  
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>                          
        </div>
      </div>
      </div>
    </div>    
  </div>
</template>

<script>
export default {
  props: {
    project: Object
  }
}
</script>

<style>
  .item-antecedent {
    margin-left: 2rem;
  }
</style>