<template>
  <div>
    <!-- {{project}} -->
    <vx-card>
      <div class="text-center">
        <h4>Concepto general</h4>
      </div>
      <br> 
      <div class="text-center">
        <h1 v-bind:style="'color: ' + colorConceptGeneralText(this.project.overall_final)">{{this.project.overall_final_name}}</h1>
      </div>
      <br>                    
      <h3 class="mb-3 text-center" v-bind:style="'color: ' + colorConceptGeneralText(this.project.overall_final)">
        {{this.project.overall_final_detail}}
      </h3>
      <br>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/3 mb-base">
        </div>      
        <div class="vx-col w-full lg:w-1/3 mb-base">          
          <div :key="index" v-for="(data, index) in this.project.general_concepts">
            <div class="vx-row" v-if="data.value > 0 && data.name != 'Concepto General'" style="display: flex; justify-content: center; align-items: center; padding: 5px;">
              <div class="vx-col w-full lg:w-1/2 mb-base">
                <h6 class="text-left">
                  {{data.name}}
                </h6>      
              </div>                  
              <div class="vx-col w-full lg:w-1/2 mb-base" style="display:flex;">
                <star-rating read-only inactive-color="rgb(153, 153, 153)" v-bind:active-color="colorConceptGeneral(data.value)" :rating="data.value" :max-rating="5" :star-size="30">
                </star-rating>
                <feather-icon @mouseover="viewAlert(`message${index}`)" @mouseout="hiddenAlert(`message${index}`)" style="margin-left: 13px;" icon="InfoIcon"></feather-icon>
              </div>
              <div :id="`message`+index"  style="visibility: hidden; display: flex; justify-content: center; align-items: center; position: relative; left: 32%; margin-bottom: -69%; bottom: 13vh;">
                <div style="background: #FAF8FF; border: 1px solid #D1C9E0; box-sizing: border-box; border-radius: 2.5px; transform: rotate(45deg); position: relative;left: 87%; bottom: 82px; width:12px; height: 12px;">
                </div>
                <div style="background: #FAF8FF; border: 1px solid #D1C9E0; position: relative;left: 85%; bottom: 83px; box-sizing: border-box; border-radius: 5px; width: 314px; word-break:break-word;">
                  <div style="margin:12px; font-weight:500;">
                    {{data.value}} {{data.value > 1 ? 'estrellas' : 'estrella'}}
                  </div>
                  <div style="margin: 15px; text-align: left;font-size: 13px;line-height: 15px;">
                    {{data.observation}}
                  </div>
                </div>
              </div>         
            </div>
          </div>              
        </div>      
        <div class="vx-col w-full lg:w-1/3 mb-base">
        </div>
      </div>   
    </vx-card>
  </div>

</template>

<script>
import StarRating from 'vue-star-rating'
export default {
  props: {
    project: Object
  },
  data (){
    return{
      explains : [
        "5 Estrellas. Fue posible validar toda la información y obtuvimos resultados positivos.",
        "4 Estrellas. La mayor parte de la información pudo ser ver verificada satisfactoriamente, pero se encontraron datos menores que recomendamos considerar..",
        "3 Menos del 80% de la información pudo ser verificada con resultados positivos.",
        "2 Estrellas. Una parte importante de la información no pudo ser verificada o presentó resultados adversos.",
        "1 Estrellas. La información encontrada presentó resultados generales adversos.",
      ]
    }
  },
  methods: {
    colorConceptGeneral(value){
      var color = "rgb(153, 153, 153)"
      if(value >= 4){
        color = "rgb(80, 181, 75)"
      }
      if(value == 3){
        color = "rgb(255, 159, 67)"
      }        
      if(value <= 2){
        color = "rgb(201, 74, 23)"
      }        
      return color;
    },
    colorConceptGeneralText(value){
      var color = "rgb(153, 153, 153)"
      if(value == 0){
        color = "rgb(201, 74, 23)"
      }              
      if(value == 1){
        color = "rgb(80, 181, 75)"
      }
      if(value == 2){
        color = '#FF9F43'
      }
      return color;
    },    
    classConceptGeneral(value, position){
      var classPosition = "cuadroy"
      if(value == position){
        if(value >= 4){
          classPosition = "cuadrov"
        }
        if(value == 3){
          classPosition = "cuadroa"
        }        
        if(value <= 2){
          classPosition = "cuadror"
        }        
      }
      return classPosition;
    },
    viewAlert(idDiv1) {
      document.getElementById(idDiv1).style.visibility = 'visible'
    },
    hiddenAlert(idDiv1) {
      document.getElementById(idDiv1).style.visibility = 'hidden'
    }
  },
  created(){

  },
  mounted(){

  },
  components:{
    StarRating
  }
}
</script>

<style>
  .item-custom{
    list-style-type: none;
    font-weight: 300;
    /* border-bottom: 1px solid #dedede; */
    color: black;
  }
  .title-stars{
   color: #5F0090; 
  }
</style>